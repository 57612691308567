import playStore from "../Assets/Images/GooglePlayEnglish.png";
import appStore from "../Assets/Images/AppStoreEnglish.svg";

export const home = {
  head: "Seamless Delivery, Rides, and Transport at Your Fingertips",
  subHead:
    "Experience convenience with YANDI - your all-in-one app for food delivery, rides, and freight transport",
  playStore: playStore,
  appStore: appStore,
};

export const features = {
  head: "Features",
  subHead:
    "Explore YANDI's top features designed to make your life easier and more convenient.",
  box1: {
    head: "Easy Registration",
    subHead: "Sign up quickly and effortlessly",
  },
  box2: {
    head: "Real-time Tracking",
    subHead: "Monitor progress instantly",
  },
  box3: {
    head: "Secure Payments",
    subHead: "Safeguard transactions with robust security",
  },
  box4: {
    head: "Friendly Interface",
    subHead: "Enjoy a seamless, user-friendly design",
  },
};

export const services = {
  head: "Our Services",
  subHead:
    "Comprehensive solutions for rides, food delivery, and freight transport at your fingertips.",
  carRidingServices: {
    head: "Car Riding Services",
    subHead:
      "Book rides with ease, choosing from a range of vehicles including cars, motorbikes, and tricycles",
  },
  foodDeliveryServices: {
    head: "Food Delivery Services",
    subHead:
      "Order your favorite meals from local restaurants and get them delivered fast and fresh",
  },
  smallFreightTransportServices: {
    head: "Small Freight Transport Services",
    subHead:
      "Send packages securely and quickly, with options for various types of vehicles",
  },
};

export const becomeADriver = {
  head: `Become a YANDI Driver,Earn on Your Own Schedule`,
  subHead:
    "Join YANDI as a driver for food delivery, car rides, or freight transport. Enjoy flexible working hours, competitive earnings, and the freedom to choose your rides",
};

export const about = {
  head: "About Yandi",
  subHead:
    "Learn how YANDI simplifies your daily needs with reliable delivery, rides, and transport services",
  content:
    "YANDI is a versatile app providing efficient and reliable services for food delivery, car rides, and small freight transport. Whether you're hungry, need a ride, or have a package to send, YANDI has got you covered.",
};

export const experienceYandi = {
  head: "Ready To Experience Yandi?",
  subHead: "Download the App",
};

export const footer = {
  logoSection: {
    content: "Your All-in-One Solution for Delivery, Rides, and Transport",
    email: "information@yandiapp.com",
    contactNumber: "+224 611 04 17 17",
  },
  menu1: {
    head: "Menu",
    subHead1: "Home",
    subHead2: "Features",
    subHead3: "Services",
    subHead4: "Driver",
    subHead5: "Become a Driver",
  },
  menu2: {
    head: "Company",
    subHead1: "About",
    subHead2: "Contact us ",
    subHead3: "FAQ's",
    subHead4: "Privacy Policy",
  },
  menu3: {
    head: "Social Media",
  },
  copyRight: "Copyright © 2025 YANDI | All Rights Reserved",
};

export const modal = {
  head: "Get In Touch",
  subHead:
    "Have questions or need assistance? Our team is here to help you with any inquiries, support requests, or feedback. Reach out to us anytime, and we'll respond promptly.",
  Name: "Name",
  Email: "Email",
  Message: "Message",
  button: "Submit",
};

export const English = {
  translation: {
    modal: modal,
    footer: footer,
    experienceYandi: experienceYandi,
    about: about,
    becomeADriver: becomeADriver,
    services: services,
    features: features,
    home: home,
  },
};
